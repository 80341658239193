import {Component} from 'react';
import AuthService from '../services/AuthService';
import {Redirect} from 'react-router-dom'
import '@shopify/polaris/build/esm/styles.css';
import { Spinner } from '@shopify/polaris';
window.auth = new AuthService();

class Callback extends Component {
    constructor(){
        super()
        this.state = {
            isAuthenticated: false
        }
    }

    async componentDidMount() {
        try {
          const response = await window.auth.loginCallback();
          if(response.state === undefined){
            this.setState({isAuthenticated: true})
          }
        } catch (error) {
          console.log(error);
        }
    }
    
    render () {
        const { isAuthenticated } = this.state;

        if (isAuthenticated) {
            return (<Redirect to='/'/>);
        }
     
        return (<Spinner accessibilityLabel="Spinner example" size="large" />);
    }
}

export default Callback;