import { UserManager, WebStorageStateStore } from 'oidc-client';

// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../config/config.${process.env.REACT_APP_ENV}`);

export default class AuthService {
    constructor() {
        const defaultSettings = {
            client_id: 'integrations.shopify',
            response_type: 'id_token token',
            scope: 'openid profile locate2u.shopify',
            userStore: new WebStorageStateStore({
                store: window.localStorage
            }),
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 10
        };

        const settings = Object.assign({}, defaultSettings, config);

        this.userManager = new UserManager(settings);
    }

    async getUser() {
        const response = await this.userManager.getUser();
        return response;
    }

    async loginCallback() {
        //const response = await this.userManager.signinRedirectCallback();
        const response = await this.userManager.signinPopupCallback();
        return response;
    }

    async login() {
        window.localStorage.clear();
        //const response = await this.userManager.signinRedirect();
        const response = await this.userManager.signinPopup();
        return response;
    }

    async register() {
        const response = await this.userManager.signinRedirect({
            extraQueryParams: {
                page: 'register'
            }
        });
        return response;
    }

    async logout() {
        window.localStorage.clear();
        const response = await this.userManager.signoutRedirect();
        return response;
    }

    async isLoggedIn() {
        const user = await this.getUser();

        return user !== null && !user.expired;
    }
}
