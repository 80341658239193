import React, { useState, useCallback } from 'react';
import { AlphaStack , Text, ButtonGroup, Button, TextField, Toast, Frame, Select, ChoiceList, Layout, Inline } from '@shopify/polaris';
import axios from 'axios';

function OptionSettings(props){
    // TODO: should be passing storeURL
    const { settings, shopCode, storeUrl } = props.state;

    if(!settings)
        return null;

    let { isAccountConnected, createStops, fulfillShopifyOrders, conditionalSyncTags, dateFormat, timeFormat, hasCustomFormatter, allowedPaymentStatus } = settings;
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentCreateStopsValue, setCreateStop] = useState(createStops ?? false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentFulfillShopifyOrders, setfulfillOrders] = useState(fulfillShopifyOrders ?? false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [conditionalSyncTagsValue, setValue] = useState(conditionalSyncTags);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showToast, setToastMessage] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toggleActive = useCallback(() => setToastMessage((showToast) => !showToast), []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentTimeFormatValue, setTimeFormat] = useState(timeFormat || "h:mm a");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleSelectTimeFormat = useCallback((value) => setTimeFormat(value), []);

    const timeFormatOptions = [
        { label: '12 hour - E.g. 2:30 PM', value: 'h:mm a' },
        { label: '24 hour - E.g. 14:30', value: 'hh:mm' },
    ];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentDateFormatValue, setDateFormat] = useState(dateFormat || "MM/dd/yyyy");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleSelectDateFormat = useCallback((value) => setDateFormat(value), []);

    const DateFormatOptions = [
        { label: 'mm/dd/yyyy - E.g. 12/31/2022', value: 'MM/dd/yyyy' },
        { label: 'dd/mm/yyyy - E.g. 31/12/2022', value: 'dd/MM/yyyy' },
        { label: 'Sat 31 Dec 2022', value: 'ddd dd MMM yyyy' },
        { label: 'Sat Dec 31 2022', value: 'ddd MMM dd yyyy' },
    ];
    //// eslint-disable-next-line react-hooks/rules-of-hooks
    //const [PendingChecked, setPendingChecked] = useState(false);
    //// eslint-disable-next-line react-hooks/rules-of-hooks
    //const [PaidChecked, setPaidChecked] = useState(false);

    let paymentStatus = ["paid"];

    if (allowedPaymentStatus != null && allowedPaymentStatus.length > 0) {
        paymentStatus = allowedPaymentStatus;
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(paymentStatus);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handlePaymentStatusChange = useCallback((value) => setSelectedPaymentStatus(value), []);

    let message = "Settings successfully saved!";

    const toastMarkup = showToast ? (
        <Toast content={message} onDismiss={toggleActive} />
    ) : null;

    const spacer = {
        marginTop: "40px"
    };

    const bottomLine = {
        borderBottom: "1px solid #ddd",
        padding: "20px 0 20px 0"
    }

    const saveButtonDiv = {
        textAlign: "right",
        marginTop: "20px"

    }

    if(!isAccountConnected)
        return null;

    const handleOptionSettingsClick = async (value, type) => {        
        //var response = await onHandleOptionSettingsClick(value, type, props.storeUrl);

        //// show error message
        //if(response.status !== 200)
        //    return null;
        
        if(type === 'createStops'){
            setCreateStop(value);
        }else {
            setfulfillOrders(value);
        }
    }

    const handleTriggerChange = async (value) => {
        setValue(value);
    }

    const handleSaveChanges = async () => {

        const data = {
            createStops: currentCreateStopsValue,
            fulfillShopifyOrders: currentFulfillShopifyOrders,
            conditionalSyncTags: conditionalSyncTagsValue,
            dateFormat: currentDateFormatValue,
            timeFormat: currentTimeFormatValue,
            allowedPaymentStatus: selectedPaymentStatus
        }

        const resp = await onHandleSaveChanges(storeUrl, data, shopCode);

        if (resp.status === 200) {
            setToastMessage(true);
        } else {
            message = "Failed to update settings.";
            setToastMessage(true);
        }
    }

    // TODO: Create a component for the stack so it can be reusable
    return(      
        <div style={spacer}>
            <Frame>
            <div style={bottomLine}>
            <Layout>
                <Layout.Section style={{marginTop: '0px'}}>
                    <AlphaStack>
                    <Text variant="headingMd" as="h2">
                        Create Stops
                    </Text>
                    <Text color="subdued" as="p">
                        Send new and updated orders across to Locate2u
                    </Text>
                    </AlphaStack>
                </Layout.Section>
                <Layout.Section oneThird>
                    <AlphaStack sectioned>
                        <Inline align="end">
                            <ButtonGroup segmented>
                                <Button primary={currentCreateStopsValue} onClick={ () => handleOptionSettingsClick(true, 'createStops')}>
                                    On
                                </Button>
                                <Button primary={!currentCreateStopsValue} onClick={ () => handleOptionSettingsClick(false,'createStops')}>
                                    Off
                                </Button>
                            </ButtonGroup>
                        </Inline>
                    </AlphaStack>
                </Layout.Section>
            </Layout>
            </div>

            <div style={bottomLine}>

            <Layout>
                <Layout.Section style={{marginTop: 0}}>
                    <AlphaStack>
                    <Text variant="headingMd" as="h2">
                        Fulfill Orders
                    </Text>
                    <Text color="subdued" as="p">
                        When a stop is completed, this will automatically fulfill the order.
                    </Text>
                    </AlphaStack>
                </Layout.Section>
                <Layout.Section oneThird>
                    <AlphaStack sectioned>
                        <Inline align="end">
                            <ButtonGroup segmented>
                                <Button  primary={currentFulfillShopifyOrders} onClick={ () => handleOptionSettingsClick(true, 'fufillOrders')}>
                                    On
                                </Button>
                                <Button primary={!currentFulfillShopifyOrders} onClick={ () => handleOptionSettingsClick(false, 'fufillOrders')}>
                                    Off
                                </Button>
                            </ButtonGroup>
                        </Inline>
                    </AlphaStack>
                </Layout.Section>
            </Layout>
            </div>

            <div style={bottomLine}>

            <Layout>
                <Layout.Section style={{marginTop: 0}}>
                    <AlphaStack>
                    <Text variant="headingMd" as="h2">
                        Conditional Sync Tags
                    </Text>
                    <Text color="subdued" as="p">
                        Only create stops based on the conditional sync tags. **For multiple tag support please separate them using comma.
                    </Text>
                    </AlphaStack>
                </Layout.Section>
                <Layout.Section oneThird>
                    <AlphaStack sectioned>
                        <Inline align="end">
                            <TextField
                                value={conditionalSyncTagsValue}
                                onChange={handleTriggerChange}
                                autoComplete="off"
                                disabled={!currentCreateStopsValue || hasCustomFormatter}
                                />
                        </Inline>
                    </AlphaStack>
                </Layout.Section>
            </Layout>
            </div>

            <div style={bottomLine}>
            <Layout>
                <Layout.Section style={{marginTop: 0}}>
                    <AlphaStack>
                    <Text variant="headingMd" as="h2">
                        Delivery Time Format
                    </Text>
                    <Text color="subdued" as="p">
                        This format will be used when reading tags that specify the delivery time
                    </Text>
                    </AlphaStack>
                </Layout.Section>
                <Layout.Section oneThird>
                    <AlphaStack sectioned>
                        <Inline align="end">
                            <Select
                                options={timeFormatOptions}
                                onChange={handleSelectTimeFormat}
                                value={currentTimeFormatValue}
                                disabled={hasCustomFormatter}
                            />
                        </Inline>
                    </AlphaStack>
                </Layout.Section>
            </Layout>
            </div>

            <div style={bottomLine}>
            <Layout>
                <Layout.Section style={{marginTop: 0}}>
                    <AlphaStack>
                    <Text variant="headingMd" as="h2">
                        Delivery Date Format
                    </Text>
                    <Text color="subdued" as="p">
                        This format will be used when reading tags that specify the delivery date
                    </Text>
                    </AlphaStack>
                </Layout.Section>
                <Layout.Section oneThird>
                    <AlphaStack sectioned>
                        <Inline align="end">
                        <Select
                            options={DateFormatOptions}
                            onChange={handleSelectDateFormat}
                            value={currentDateFormatValue}
                            disabled={hasCustomFormatter}
                        />
                        </Inline>
                    </AlphaStack>
                </Layout.Section>
            </Layout>
            </div>


            <div style={bottomLine}>
                <AlphaStack>
                    <div>
                        <Text>Order Payment Status</Text>
                        <p>Stops will only be created based on the order statuses chosen below</p>

                        <ChoiceList
                            allowMultiple
                            choices={[
                                {
                                    label: 'Paid',
                                    value: 'paid',
                                },
                                {
                                    label: 'Pending',
                                    value: 'pending',
                                },
                                {
                                    label: 'Authorized',
                                    value: 'authorized',
                                },
                                {
                                    label: 'Partially Paid',
                                    value: 'partially_paid',
                                }
                            ]}
                            selected={selectedPaymentStatus}
                            onChange={handlePaymentStatusChange}
                        />
                    </div>
                </AlphaStack>
            </div>

            <div>
                <div style={saveButtonDiv}>
                    <Button primary onClick={handleSaveChanges}>Save changes</Button>
                </div>
                
                <span>**Note: If you require a more complex integration please send us a message at <a target="_blank" href="mailto:support@locate2u.com" rel="noreferrer">support@locate2u.com</a>.</span>
            </div>

            {toastMarkup}
            </Frame>
        </div>      
    )
}

async function onHandleSaveChanges(storeUrl, data, state) {
    const user = await window.auth.getUser();
    const accessToken = user.access_token;

    const request = Object.assign({}, { method: 'put', data: data  }, {
        url: `/api/settings`
    });

    request.headers = Object.assign({}, {}, {
        Authorization: `Bearer ${accessToken}`,
        "X-Shopify-Shop-Domain": storeUrl,
        "X-Locate2u-State": encodeURIComponent(state)
    });

    return new Promise((resolve, reject) => {
        axios(request).then(
            (r) => {
                const { status } = r;
                resolve({ data: data, status });
            },
            (e) => {
                const { data, status } = e.response;
                /* eslint-disable prefer-promise-reject-errors */
                reject({ data, status });
            }
        );
    });
}


//async function onHandleOptionSettingsClick(value, type, storeUrl){
//    const user = await window.auth.getUser();
//    const accessToken = user.access_token;

//    const request = Object.assign({}, { method: 'put'}, {
//        url: `/api/settings?value=${value}&type=${type}`
//    });

//    request.headers = Object.assign({}, {}, {
//        Authorization: `Bearer ${accessToken}`,
//        "X-Shopify-Shop-Domain": storeUrl
//    });

//    return new Promise((resolve, reject) => {
//        axios(request).then(
//            (r) => {
//                const { status } = r;
//                resolve({ data: value, status });
//            },
//            (e) => {
//                const { data, status } = e.response;
//                /* eslint-disable prefer-promise-reject-errors */
//                reject({ data, status });
//            }
//        );
//    });
//}

export default OptionSettings;