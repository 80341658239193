import { Component } from 'react';
import { AlphaCard, AlphaStack, Text, Modal, Spinner } from '@shopify/polaris';
import axios from 'axios';
import { BannerMessage, OptionSettings, Account } from '../components';

class Home extends Component {

    constructor(){
        super();        
        this.state = {
            name: "", 
            email: "",
            isLoggedIn: false,
            isAccountConnected: false,
            settings: null,
            isLoading: true,
            errorMessageList: [],
            showErrorMessage: false,
            storeUrl: null,
            shopCode: null
        }

        this.onHandleModalClose = this.onHandleModalClose.bind(this);
        this.onhandleDisconnect = this.onhandleDisconnect.bind(this);
        this.onhandleConnect = this.onhandleConnect.bind(this);
    } 

    async componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const storeUrl = urlSearchParams.get("shop");
        const state = urlSearchParams.get("state");
        const result = await window.auth.isLoggedIn();

        if (!result) {

            try {

                await VerifySession(storeUrl, state);

            } catch (e) {
                window.location.href = `https://${window.location.host}`;
                return;
            }

            this.setState( prevState => ({
                ...prevState,
                isLoading: false,
                storeUrl: storeUrl,
                shopCode: state
            }));

            return;
        }

        try {
            var { data: { data } } = await GetSettings(storeUrl, state);

            this.setState({
                name: data.name,
                email: data.email,
                isAccountConnected : data.isAccountConnected,
                settings: data,
                isLoggedIn: result,
                showDisconnectModal: false,
                isLoading: false,
                showErrorMessage: false,
                storeUrl: storeUrl,
                shopCode: state
            })
        } catch (error) {
            const {data} = error;

            var list = ProcessErrorMessages(data);
            this.setState(prevState => ({
                ...prevState,
                isLoggedIn: true,
                isLoading: false,
                errorMessageList: list,
                showErrorMessage: true,
            }));
        }
    }

    async onhandleConnect(res) {
        const { data, status } = res;

        if(status === 200){
            this.setState( prevState => ({
                ...prevState,
                isAccountConnected: data.isAccountConnected,
                settings: data,
                isLoading: false,
            }));
        }else if(res === "login"){
            try {
                var result = await GetSettings(this.state.storeUrl, this.state.shopCode);
                var resData = result.data.data;

                if (resData.status === 301) {
                    window.location.href = window.location.hostname;
                    return;
                }

                this.setState(prevState => ({
                    ...prevState,
                    name: resData.name,
                    email: resData.email,
                    isAccountConnected : resData.isAccountConnected,
                    settings: resData,
                    isLoggedIn: true,
                    showDisconnectModal: false,
                    isLoading: false,
                    showErrorMessage: false,
                }));
            } catch (error) {
                const {data} = error;
    
                var list = ProcessErrorMessages(data);

                this.setState(prevState => ({
                    ...prevState,
                    isLoggedIn: true,
                    isLoading: false,
                    errorMessageList: list,
                    showErrorMessage: true,
                }));
            }
        }
        else if (res === "Disconnect"){
            this.setState(prevState => ({
                ...prevState,
                showDisconnectModal: true
            }));
        }
        else {
            var errors = ProcessErrorMessages(data);
            this.setState(prevState => ({
                ...prevState,
                isLoggedIn: true,
                isLoading: false,
                errorMessageList: errors,
                showErrorMessage: true,
            }));
        }

    }

    onHandleModalClose(){
        this.setState( prevState => ({
            ...prevState,
            showDisconnectModal: !this.state.showDisconnectModal
        }))
    }

    async onhandleDisconnect(){

        this.setState( prevState => ({
            ...prevState,
            showDisconnectModal: false,
            isLoading: true
        }))

        const user = await window.auth.getUser();
        const accessToken = user.access_token;

        const request = Object.assign({}, { method: 'post'}, {
            url: '/api/settings/disconnect'
        });

        request.headers = Object.assign({}, {}, {
            Authorization: `Bearer ${accessToken}`,
            "X-Shopify-Shop-Domain": this.state.storeUrl,
            "X-Locate2u-State": encodeURIComponent(this.state.shopCode)
        });

        return new Promise((resolve, reject) => {
            axios(request).then(
                async (r) => {
                    const { data, status } = r;
                    this.setState({
                        name: null,
                        isAccountConnected : false,
                        settings: null,
                        isLoggedIn: false,
                    })
                    await window.auth.logout();
                    window.localStorage.clear();
                    window.parent.location.href = `https://${this.state.storeUrl}/admin/apps`;
                    resolve({ data, status });
                },
                (e) => {
                    const { data, status } = e.response;

                    var temp = [];

                    data.forEach(element => {
                        temp.push(element);
                    });
                    
                    /* eslint-disable prefer-promise-reject-errors */
                    //
                    this.setState(prevState => ({
                        ...prevState,
                        showErrorMessage: true,
                        errorMessageList: temp,
                        showDisconnectModal: false,
                        isLoading: false,
                    }));

                    reject({ data, status });
                }
            );
        });
    }

    render(){
        const isAccountConnected = this.state.isAccountConnected;
        const isLoading = this.state.isLoading;
        console.log(this.state.storeUrl);

        if(isLoading){
            return (<Spinner accessibilityLabel="Spinner example" size="large" />);
        }else {

            let welcomeMessage;

            if(!isAccountConnected){
                welcomeMessage =             
                <AlphaCard>
                    <Text variant="headingMd" as="h6">Locate2u Shipping</Text>
                    <AlphaStack>
                    <p style={{marginTop: '1.25rem'}}>With the Locate2u shipping app you will experience a simplified shipping process enabling you 
                        to automatically send paid orders from Shopify to Locate2u and convert them to stops. Our plugin has the ability 
                        to also auto-fulfill orders in Shopify once the corresponding stop to that order is completed.
                    </p>
                    <p style={{marginTop: '1.25rem'}}>If you require a more complex integration please send us a message at <a target="_blank" href="mailto:hello@locate2u.com" rel="noreferrer">hello@locate2u.com</a>.</p>
                    <p style={{marginTop: '1.25rem'}}>
                        To successfully use this app you must already have a Locate2u account. If you don't have one please click the request a call button &nbsp;
                        <a target="_blank" href="https://www.locate2u.com/" rel="noreferrer">here</a>, so our sales representatives can help you get set up.
                    </p>
                    <p style={{marginTop: '1.25rem'}}>Click <a target="_blank" href="https://www.locate2u.com/" rel="noreferrer">here</a> to learn more about Locate2u.</p>
                    </AlphaStack>
                </AlphaCard>
            }else {
                welcomeMessage = <Text variant="heading3xl" as="h2">Settings</Text>;
            }
    
            return (
                <div>
                    {welcomeMessage}
                    {this.state.showErrorMessage ? <br/> : null }
                    <BannerMessage state={this.state} />
                    
                    <Account state={this.state} onHandleConnect={this.onhandleConnect}/>
                    <OptionSettings state={this.state} />
                    <Modal
                        open={this.state.showDisconnectModal}
                        onClose={this.onHandleModalClose}
                        title="Disconnect account?"
                        primaryAction={{
                            content: 'Disconnect',
                            onAction: this.onhandleDisconnect
                        }}
                        secondaryActions={[
                            {
                            content: 'Cancel',
                            onAction: this.onHandleModalClose,
                            },
                        ]}
                        >
                        <Modal.Section>
                            <AlphaStack>
                            <p>
                                By disconnecting, no further stops will be created inside the locate2u platform for your Shopify orders.
                                Fulfillment functionality will also be disabled once your account is disconnected.
                            </p>
                            </AlphaStack>
                        </Modal.Section>
                    </Modal>
                </div>
            );
        }

    }

}

const ProcessErrorMessages = (data) => {
    var list = [];
    const message = "Something went wrong, please try again later."

    if(data){
        if(Array.isArray(data)){
            data.forEach(e => {
                list.push(e);
            });
        }else {
            list.push({message: data});
        }
    }else {
        list.push({message: message});
    }

    return list;
}

const VerifySession = async (storeUrl, state) => {
    const request = Object.assign({}, { method: 'get' }, {
        url: `/api/settings/verify`
    });

    request.headers = Object.assign({}, {}, {
        "X-Shopify-Shop-Domain": storeUrl,
        "X-Locate2u-State": encodeURIComponent(state)
    });

    return new Promise((resolve, reject) => {
        axios(request).then(
            (r) => {
                const { data, status } = r;
                resolve({ data, status });
            },
            (e) => {
                const { data, status } = e.response;
                /* eslint-disable prefer-promise-reject-errors */
                reject({ data, status });
            }
        );
    });
}


const GetSettings = async (storeUrl, state) => {
    const user = await window.auth.getUser();
    const accessToken = user.access_token;

    const request = Object.assign({}, { method: 'get' }, {
        url: '/api/settings'
    });

    request.headers = Object.assign({}, {}, {
        Authorization: `Bearer ${accessToken}`,
        "X-Shopify-Shop-Domain": storeUrl,
        "X-Locate2u-State": encodeURIComponent(state)
    });

    return new Promise((resolve, reject) => {
        axios(request).then(
            (r) => {
                const { data, status } = r;
                resolve({ data, status });
            },
            (e) => {
                const { data, status } = e.response;
                /* eslint-disable prefer-promise-reject-errors */
                reject({ data, status });
            }
        );
    });
}

export default Home;