import React from 'react';
import { Banner } from '@shopify/polaris';

function BannerMessage(props){
    const { errorMessageList, showErrorMessage } = props.state;
    if(!showErrorMessage) 
        return null;

    // TODO: status and title should be coming from props

    return (
        <Banner
            title="Error"
            status="critical"
        >
            <ul>
                { errorMessageList.map((error, index) => (<li key={index}>{error.message}</li>))}
            </ul>
      </Banner>);
}

export default BannerMessage;