import { Component } from 'react';
import axios from 'axios';
import { Card, Form, FormLayout, TextField, Button } from '@shopify/polaris';

class Install extends Component {

    constructor(){
        super();
        this.state = {
            storeUrl: null,
            errorMessage: null
        }

        this.handleUrlChange = this.handleUrlChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const storeUrl = urlSearchParams.get("shop");

        if(storeUrl == null){
            this.setState({
                storeUrl: storeUrl,
                errorMessage: null
            });
        }
    }

    handleUrlChange(value) {
        this.setState({ storeUrl: value });
    }

    handleSubmit() {
        if (this.state.storeUrl == null) {
            this.setState(prevState => ({
                ...prevState,
                errorMessage: "Please enter a valid Shopify URL"
            }));
            return;
        }


        const request = Object.assign({}, { method: 'post' }, {
            url: '/api/webhooks/install?shop=' + this.state.storeUrl
        });

        request.headers = Object.assign({}, {}, {
            "X-Shopify-Shop-Domain": this.state.storeUrl,
        });

        return new Promise((resolve, reject) => {
            axios(request).then(
                async (r) => {
                    const { data } = r;
                    console.log(r);
                    window.location.href = data;
                    //this.setState({
                    //    name: null,
                    //    isAccountConnected: false,
                    //    settings: null,
                    //    isLoggedIn: false,
                    //})
                    //await window.auth.logout();
                    //window.localStorage.clear();
                    //window.parent.location.href = `https://${this.state.storeUrl}/admin/apps/locate2u`;
                    //resolve({ data, status });
                },
                (e) => {
                    console.log(e);
                    this.setState(prevState => ({
                        ...prevState,
                        errorMessage: "There was an error in installing to this url"
                    }));
                    //var temp = [];

                    //data.forEach(element => {
                    //    temp.push(element);
                    //});

                    ///* eslint-disable prefer-promise-reject-errors */
                    ////
                    //this.setState(prevState => ({
                    //    ...prevState,
                    //    showErrorMessage: true,
                    //    errorMessageList: temp,
                    //    showDisconnectModal: false,
                    //    isLoading: false,
                    //}));

                    //reject({ data, status });
                }
            );
        });
    }

    render(){

        //const url = this.state.storeUrl;
        const formStyles = {
            padding: 20,
            textAlign: 'center'
        }

        return (
            <div>
                <Card title="Install Locate2u App for Shopify">
                     <div style={formStyles}>
                    {/*<iframe src="https://player.vimeo.com/video/380651487?h=8ea069b170&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>*/}
                        <p>This app requires you to login to your shopify account to start using it.</p>

                        <Form noValidate onSubmit={this.handleSubmit}>
                        <FormLayout>
                                <TextField
                                    value={this.state.storeUrl}
                                    onChange={this.handleUrlChange}
                            label="App URL"
                            type="url"
                            autoComplete="off"
                            />

                            <Button submit>Install</Button>
                        </FormLayout>
                        </Form>
                    </div> 
                    <div style={formStyles}>
                        <p>{this.state.errorMessage}</p>
                    </div>
                </Card>
            </div>
        );
    }
}

export default Install;