import React, {useState} from 'react';
import { Link, Layout, AccountConnection, Spinner, Button } from '@shopify/polaris';
import axios from 'axios';

function Account(props){
    const { isLoggedIn, settings, showErrorMessage, storeUrl } = props.state;

    let isAccountConnected = false;
    let name = "Guest";
    let email = "";

    const [isLoading, setLoading] = useState(false);

    if(settings != null){
        isAccountConnected = settings.isAccountConnected;
        name = settings.name;
        email = settings.email;
    }

    let accountName = name;
    let buttonText = isAccountConnected ? 'Disconnect' : 'Connect';
    const details = email ? email : 'No account connected';

    if(!isLoggedIn){
        buttonText = "Login";
    }

    if((isLoggedIn && settings == null) || (isLoggedIn && showErrorMessage)){
        buttonText = "Sign out";
    }

    const terms = isAccountConnected  ? null : (
        <p>
            By clicking <strong>Connect</strong>, you agree to accept Locate2u’s{' '}
            <Link url="https://www.locate2u.com/customer-terms/" external={true}>terms and conditions</Link>.
        </p>
        );

    const handleConnect = async () => {
        if(buttonText === "Sign out"){
            await window.auth.logout();
            window.localStorage.clear();
        }else {
            setLoading(true);
            try {
                var response = await onhandleConnect(props.state);

                if(response === "Disconnect"){
                    await props.onHandleConnect("Disconnect");
                    setLoading(false);
                    return;
                }
        
                if(response){
                    const {status} = response;
                    if(status === 200){
                        await props.onHandleConnect(response);
                    }else {
                        await props.onHandleConnect("login");
                    }
                    setLoading(false);
                }
            } catch (error) {
                props.onHandleConnect(error);
                setLoading(false);
            }

        }
    }
        
    let bottomLine = {
        marginTop: "20px"
    };

    if(isAccountConnected){
        bottomLine = {
            borderBottom: "1px solid #ddd",
            marginTop: "20px"
        }
    }

    const handleSignOut = async () => {
        await window.auth.logout();
    }

    return (
        <div style={bottomLine}>
        <Layout>
        <Layout.AnnotatedSection
            id="storeDetails"
            title="Connected store"
            description="Connect your Shopify store with your Locate2u account."
        >
            {!isLoading && storeUrl != null?
            <AccountConnection
                sectioned
                accountName={accountName}
                connected={isAccountConnected}
                title={accountName}
                action={{
                    content: buttonText,
                    onAction: handleConnect,
                }}
                details={details}
                termsOfService={terms}
            />
            :
            <Spinner accessibilityLabel="Spinner example" size="large" />
            }

            {!isLoading && storeUrl != null && isLoggedIn && buttonText !== "Disconnect" ?
                <div>
                    <br/>
                    Not {email}?{' '} 
                    <Button plain monochrome onClick={ () => handleSignOut() }>
                        Sign out.
                    </Button>
                </div> 
                : <div></div> }
        </Layout.AnnotatedSection>
        </Layout>
        </div>
    );
}

async function onhandleConnect(state){
    let isAccountConnected = false;
    if(state){
        isAccountConnected = state.isAccountConnected;
    }
    const result = await window.auth.isLoggedIn();

    if(!result){
        const res = await window.auth.login();
        return res;
    }else {
        let url = '/api/settings/connect';

        if(isAccountConnected)
        {
            return "Disconnect";
        }
        
        const user = await window.auth.getUser();
        const accessToken = user.access_token;

        const request = Object.assign({}, { method: 'post'}, {
            url: url
        });

        request.headers = Object.assign({}, {}, {
            Authorization: `Bearer ${accessToken}`,
            "X-Shopify-Shop-Domain": state.storeUrl,
            "X-Locate2u-State": encodeURIComponent(state.shopCode)
        });

        return new Promise((resolve, reject) => {
            axios(request).then(
                (r) => {
                    const { data, status } = r;

                    resolve({ data, status });
                },
                (e) => {
                    const { data, status } = e.response;
                
                    /* eslint-disable prefer-promise-reject-errors */
                    reject({ data, status });
                }
            );
        });
    }
}

export default Account;