import './App.css';
import React from 'react';
import Home from './pages/Home';
import Callback from './pages/callback';
import Install from './pages/Install';
import '@shopify/polaris/build/esm/styles.css';
import {AppProvider, Page} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { BrowserRouter as Router, Route } from 'react-router-dom';

class App extends React.Component {
    render() {
        //const { Component, pageProps, host } = this.props;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const storeUrl = urlSearchParams.get("shop");

        const header = {
            backgroundColor: "#1c3e61",
            textAlign: "center",
            padding: 15,
            paddingBottom: 10
        }

        const headerLogo = {
            width: 150,
        }

        return (
            <Router>
                <div style={header}>
                    <img style={headerLogo} src="/img/locate2u-logo.png" alt="locate2u logo" />
                </div>
                <AppProvider i18n={enTranslations}>
                    <Page title="Dashboard">
                        <Route exact path="/" component={storeUrl != null ? Home : Install} />
                        <Route path="/callback" component={Callback} />
                        <Route path="/login" component={Install}/> 
                    </Page>
                </AppProvider>              
            </Router>
          );
    }
}

export default App;
